import React, {Component} from 'react';
// @material-ui/core components
import {withStyles} from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import Select from "@material-ui/core/Select";
import ListItemText from "@material-ui/core/ListItemText";
import Button from "@material-ui/core/Button";
import Checkbox from "@material-ui/core/Checkbox";
import MenuItem from "@material-ui/core/MenuItem";
import Input from "@material-ui/core/Input";
import {formatDate} from "../../util/DateUtils.js"
import InputAdornment from "@material-ui/core/InputAdornment";
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import SendIcon from '@material-ui/icons/Send';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import GridContainer from "components/Grid/GridContainer.js";
import ScheduleIcon from '@material-ui/icons/Schedule';
import ParcelsTable from "components/Table/ParcelsTable.js";
import EmergencyParcelDialog from "views/Parcels/EmergencyParcelDialog.js";
import {whiteColor} from "assets/jss/material-dashboard-react.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardFooter from "components/Card/CardFooter.js";
import CardBody from "components/Card/CardBody.js";
import ApiService from "services/ApiService.js";
import Pagination from '@material-ui/lab/Pagination';
import LocalShippingRoundedIcon from '@material-ui/icons/LocalShippingRounded';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CloudDownloadOutlinedIcon from '@material-ui/icons/CloudDownloadOutlined';
import ArchiveRoundedIcon from '@material-ui/icons/ArchiveRounded';
import DirectionsIcon from '@material-ui/icons/Directions';
import WarningIcon from '@material-ui/icons/Warning';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import DateRangeIcon from '@material-ui/icons/DateRange';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import {format, isFuture, isToday, parse} from 'date-fns'
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {DatePicker, MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import endOfWeek from "date-fns/endOfWeek";
import {enGB} from 'date-fns/locale'

const styles = {
    whiteColor: {
        color: "white"
    },
    cardCategoryWhite: {
        "&,& a,& a:hover,& a:focus": {
            color: "rgba(255,255,255,.62)",
            margin: "0",
            fontSize: "14px",
            marginTop: "0",
            marginBottom: "0"
        },
        "& a,& a:hover,& a:focus": {
            color: "#FFFFFF"
        }
    },
    pagination: {
        alignItems: 'right'
    },
    cardTitleWhite: {
        color: "#FFFFFF",
        marginTop: "0px",
        minHeight: "auto",
        fontWeight: "300",
        fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
        marginBottom: "3px",
        textDecoration: "none",
        "& small": {
            color: "#777",
            fontSize: "65%",
            fontWeight: "400",
            lineHeight: "1"
        }
    }
};

const REFERRAL_EDITABLE_FIELDS = ["referralAgency", "coronavirus", "reason", "groupsMember", "nameOfAuthorisedSignatory", "emailOfAuthorisedSignatory",
    "nappiesCore", "nappiesOther", "toiletriesCore", "toiletriesOther", "babyMilkCore", "babyMilkOther",
    "homelessOrTemporaryAccommodation", "cookingFacilities", "modeOfDelivery", "reasonForDelivery",
    "organisationsSupporting", "furtherInfo", "frequencyOfDelivery", "numberOfDeliveries", "permissionToContact",
    "cannotAffordToPurchaseFood", "appliances", "cashPilotStatusNew", "cashPilotContactedBy", "dateParticipationConfirmed",
    "cashPilotGroup", "dateDroppedOutOfCashPilot", "supportType"];

const GUEST_EDITABLE_FIELDS = ["fullName", "contactNumbers", "postcode", "address", "disabled", "disabledInfo", "accessibilityIssues",
    "gender", "numberOfPeople", "ethnicityCore", "ethnicityOther", "dob", "ages", "faithCore", "faithOther",
    "dietaryRequirementsCore", "dietaryRequirementsOther", "languagesSpokenCore", "languagesSpokenOther"];
;

class Parcels extends Component {

    constructor(props) {
        super(props);
        this.fetchParcels = this.fetchParcels.bind(this);
        this.changePage = this.changePage.bind(this);
        this.performAction = this.performAction.bind(this);
        this.performReferralAction = this.performReferralAction.bind(this);
        this.performDeliveryAction = this.performDeliveryAction.bind(this);
        this.setOrderBy = this.setOrderBy.bind(this);
        this.setDateFilter = this.setDateFilter.bind(this);
        this.setModeOfDeliveryFilter = this.setModeOfDeliveryFilter.bind(this);
        this.archiveReferral = this.archiveReferral.bind(this);
        this.switchReferralTypeToReadyMeals = this.switchReferralTypeToReadyMeals.bind(this);
        this.setCollectionHubFilter = this.setCollectionHubFilter.bind(this);
        this.setParcelStatusFilter = this.setParcelStatusFilter.bind(this);
        this.setCashPilotStatusFilter = this.setCashPilotStatusFilter.bind(this);
        this.setSupportTypeFilter = this.setSupportTypeFilter.bind(this);
        this.fetchReferral = this.fetchReferral.bind(this);
        this.editReferral = this.editReferral.bind(this);
        this.editFurtherAssessment = this.editFurtherAssessment.bind(this);
        this.createFurtherAssessment = this.createFurtherAssessment.bind(this);
        this.select = this.select.bind(this);
        this.deselect = this.deselect.bind(this);
        this.selectAll = this.selectAll.bind(this);
        this.deselectAll = this.deselectAll.bind(this);
        this.performBatchAction = this.performBatchAction.bind(this);
        this.getActions = this.getActions.bind(this);
        this.filteredPath = this.filteredPath.bind(this);
        this.saveEmergencyParcel = this.saveEmergencyParcel.bind(this);
        this.state = {
            collectionHubFilter: ['all'],
            modeOfDeliveryFilter: 'all',
            parcelStatusFilter: 'all',
            cashPilotStatusFilter: '',
            supportTypeFilter: '',
            dateFilter: null,
            dateFilterOpen: false,
            numberOfResults: 0,
            numberOfPages: 1,
            page: 1,
            itemsPerPage: 20,
            parcels: {},
            referrals: {},
            parcelsOrder: [],
            selectedValues: [],
            openCollected: false,
            openSchedule: false,
            openSendScheduledTextDialog: false,
            emergencyParcelDialogOpen: false,
            sendScheduledTextStartTime: new Date(2020, 1, 1, 13, 0, 0, 0),
            sendScheduledTextEndTime: new Date(2020, 1, 1, 17, 0, 0, 0),
            orderBy: "timestamp",
            order: "desc"
        }
    }

    componentDidMount() {
        this.fetchParcels()
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.searchParam !== this.props.searchParam) {
            this.setState({ page: 1 })
        }
        if (prevProps.collectionHubs !== this.props.collectionHubs) {
            this.state.collectionHubFilter = this.props.collectionHubs.filter(hub => hub.active).map(hub => hub.name).concat(["all"])
        }
        if (prevState.orderBy !== this.state.orderBy
            || prevState.order !== this.state.order
            || prevState.modeOfDeliveryFilter !== this.state.modeOfDeliveryFilter
            || prevState.collectionHubFilter !== this.state.collectionHubFilter
            || prevState.parcelStatusFilter !== this.state.parcelStatusFilter
            || prevState.cashPilotStatusFilter !== this.state.cashPilotStatusFilter
            || prevState.supportTypeFilter !== this.state.supportTypeFilter
            || prevState.dateFilter !== this.state.dateFilter
            || prevProps.searchParam !== this.props.searchParam
            || prevState.page !== this.state.page) {
            this.fetchParcels()
        }
    }

    fetchParcels() {
        const link = `/parcels?sort=${this.state.orderBy},${this.state.order}` +
            `&page=${this.state.page - 1}` +
            `&size=${this.state.itemsPerPage}` +
            `&modeOfDeliveryFilter=${this.state.modeOfDeliveryFilter}` +
            `&collectionHubFilter=${this.state.collectionHubFilter}` +
            `&parcelStatusFilter=${this.state.parcelStatusFilter}` +
            `${this.state.dateFilter && `&dateFilter=${format(this.state.dateFilter, 'dd-MM-yyyy')}` || ""}` +
            `${this.props.searchParam && `&searchString=${this.props.searchParam}` || ""}` +
            `${this.state.cashPilotStatusFilter && this.state.cashPilotStatusFilter !== "" && `&cashPilotStatusFilter=${this.state.cashPilotStatusFilter}` || ""}` +
            `${this.state.supportTypeFilter && this.state.supportTypeFilter !== "" && `&supportTypeFilter=${this.state.supportTypeFilter}` || ""}`;
        ApiService.request(link)
            .then(data => {
                this.setState({
                    parcels: data.content.reduce(function (map, obj) {
                                                 map[obj.id] = obj;
                                                 return map;
                                             }, {}),
                    parcelsOrder: data.content.map(parcel => parcel.id),
                    selectedValues: [],
                    numberOfResults: data.totalElements,
                    numberOfPages: data.totalPages
                });
            })
            .catch(err => {
                console.log(err);
            });
    }

    fetchReferral(id) {
        return ApiService.request(`/referrals/${id}`)
            .then(data => {
                var referrals = this.state.referrals;
                referrals[id] = data;
            })
            .catch(err => {
                console.log(err);
            });
    }

    editReferral(referral) {
        var referralToSend = REFERRAL_EDITABLE_FIELDS.reduce(function (result, field) {
            result[field] = referral[field];
            return result;
        }, {});
        referralToSend.guest = GUEST_EDITABLE_FIELDS.reduce(function (result, field) {
            result[field] = referral.guest[field];
            return result;
        }, {});
        ApiService.request(`/referrals/${referral.id}`, 'PUT', referralToSend)
            .then(data => {
                var referrals = this.state.referrals;
                referrals[referral.id] = referral;
                this.setState({referrals: referrals});
            })
            .catch(err => {
                console.log(err);
            });
    }

    editFurtherAssessment(furtherAssessmentId, furtherAssessment) {
        ApiService.request(`/furtherassessments/${furtherAssessmentId}`, 'PUT', furtherAssessment)
            .then(data => {
                var referrals = this.state.referrals;
                referrals[data.id] = data;
                this.setState({referrals: referrals});
            })
            .catch(err => {
                console.log(err);
            });
    }

    createFurtherAssessment(referralId, furtherAssessment) {
        return ApiService.request(`/furtherassessments/referral/${referralId}`, 'PUT', furtherAssessment)
            .then(data => {
                var referrals = this.state.referrals;
                const assessmentIds = referrals[referralId].furtherAssessments.map(assessment => assessment.id);
                referrals[referralId] = data;
                this.setState({referrals: referrals});
                return data.furtherAssessments.find(assessment => !assessmentIds.includes(assessment.id)).id
            })
            .catch(err => {
                console.log(err);
            });
    }

    setModeOfDeliveryFilter(event) {
        this.setState({modeOfDeliveryFilter: event.target.value, page: 1});
    }

    setCollectionHubFilter(event) {
        var newCollectionHubs = event.target.value
        var oldCollectionHubs = this.state.collectionHubFilter
        if (newCollectionHubs.includes('all')
            && (!oldCollectionHubs.includes("all") || newCollectionHubs.length <= 1)) {
            this.setState({collectionHubFilter: this.props.collectionHubs.filter(hub => hub.active).map(hub => hub.name).concat(["all"]), page: 1});
        } else {
            this.setState({collectionHubFilter: event.target.value.filter(val => val !== "all"), page: 1});
        }
    }

    setParcelStatusFilter(event) {
        this.setState({parcelStatusFilter: event.target.value, page: 1});
    }

    setCashPilotStatusFilter(event) {
        this.setState({cashPilotStatusFilter: event.target.value, page: 1, supportTypeFilter: ''});
    }

    setSupportTypeFilter(event) {
        this.setState({supportTypeFilter: event.target.value, page: 1});
    }

    setDateFilter(date) {
        this.setState({dateFilter: date, page: 1, cashPilotStatusFilter: '', supportTypeFilter: ''});
    }

    setOrderBy(prop) {
        const isAsc = this.state.orderBy === prop && this.state.order === 'asc';
        this.setState({order: isAsc ? 'desc' : 'asc', orderBy: prop, page: 1});
    }

    changePage(event, newValue) {
        this.setState({ page: newValue });
    }

    performAction(key, action, param1, param2) {
        var link = `/parcels/${key}/${action}`;
        if (action === "schedule" || action === "collected") {
            link = `${link}?date=${format(param1, 'dd-MM-yyyy')}&supportType=${param2 === 'CASH_VOUCHER' && 'CASH_VOUCHER' || 'FOOD_PARCEL'}`;
        } else if (action === "changeHub") {
            link = link + "?newHub=" + param1;
        } else if (action === "switchDeliveryMode") {
            link = link + "?newMode=" + param1;
        } else if (action === "setParcelFrequency") {
            link = link + "?newParcelFrequency=" + param1;
        } else if (action === "sendScheduledText") {
            link = link + "?startTime=" + format(param1, 'hh:mm') + "&endTime=" + format(param2, 'hh:mm');
        }
        return ApiService.request(link, 'PUT')
            .then(response => {
                this.state.referrals[response.id] = response;
                this.fetchParcels()
            })
    }

    performReferralAction(key, action, param1, param2) {
        var link = `/referrals/${key}/${action}`;
        if (action === "setCashPilotStatus") {
            link = link + "?cashPilotStatus=" + param1;
        } else if (action === "setCashPilotContactedBy") {
            link = link + "?cashPilotContactedBy=" + param1;
        } else if (action === "setCashPilotGroup") {
            link = link + "?cashPilotGroup=" + param1;
        } else if (action === "setSupportType") {
            link = link + "?supportType=" + param1;
        } else if (action === "safeguarding") {
            link = link + `?safeguardingFlag=${param1}&safeguardingComment=${param2}`;
        }
        return ApiService.request(link, 'PUT')
            .then(response => {
                this.state.referrals[response.id] = response;
                this.fetchParcels()
            })
    }

    archiveReferral(key, archiveReason, archiveComment, archiveDate) {
        return ApiService.request(`/referrals/${key}/archive?archiveReason=${archiveReason}&archiveDate=${formatDate(archiveDate || new Date())}`, 'PUT', archiveComment)
            .then(response => {
                var parcels = this.state.parcels;
                var parcelsOrder = this.state.parcelsOrder;
                parcels[response.id] = response
                parcelsOrder.splice(parcelsOrder.indexOf(response.id), 1)
                this.setState({parcels: parcels, parcelsOrder: parcelsOrder})
            })
    }

    switchReferralTypeToReadyMeals(key) {
        return ApiService.request(`/referrals/${key}/switchReferralType`, 'PUT')
            .then(response => {
                var parcels = this.state.parcels;
                var parcelsOrder = this.state.parcelsOrder;
                parcels[response.id] = response
                parcelsOrder.splice(parcelsOrder.indexOf(response.id), 1)
                this.setState({parcels: parcels, parcelsOrder: parcelsOrder})
            })
    }

    performDeliveryAction(key, action, param1, param2) {
        var link = `/deliveries/${key}/${action}`;
        if (action === "sendScheduledText") {
            link = `${link}?startTime=${format(param1, 'hh:mm')}&endTime=${format(param2, 'hh:mm')}`;
        }
        ApiService.request(link, 'PUT')
            .then(response => {
                this.state.referrals[response.id] = response;
                this.fetchParcels()
            })
    }

    select(id) {
        const selectedValues = this.state.selectedValues;
        selectedValues.push(id);
        this.setState({selectedValues: selectedValues});
    }

    deselect(id) {
        const selectedValues = this.state.selectedValues;
        selectedValues.splice(selectedValues.indexOf(id), 1);
        this.setState({selectedValues: selectedValues});
    }

    selectAll(id) {
        this.setState({selectedValues: Array.from(this.state.parcelsOrder)});
    }

    deselectAll(id) {
        this.setState({selectedValues: []});
    }

    performBatchAction(action, param1, param2) {
        var link = `/parcels/${action}?referralIds=${this.state.selectedValues.join()}`;
        if (action === "schedule" || action === "collected") {
            link = link + "&date=" + format(param1, 'dd-MM-yyyy');
        } else if (action === "changeHub") {
            link = link + "&newHub=" + param1;
        } else if (action === "switchDeliveryMode") {
            link = link + "&newMode=" + param1;
        } else if (action === "sendScheduledText") {
            link = link + "&startTime=" + format(param1, 'hh:mm') + "&endTime=" + format(param2, 'HH:mm');
        }
        ApiService.request(link, 'PUT')
            .then(response => {
                this.state.referrals[response.id] = response;
                this.fetchParcels()
            })
    }

    endOfNextWeek() {
        var date = new Date();
        date.setDate(date.getDate() + 7);
        return endOfWeek(date, {weekStartsOn: 1});
    }

    getActions() {
        const selectedValues = this.state.selectedValues;
        if (selectedValues.length === 0) {
            return "";
        }
        var showSchedule = selectedValues.every(referralId => this.state.parcels[referralId].modeOfDelivery === "DELIVERY" && !this.state.parcels[referralId].scheduled);
        var showCollected = selectedValues.every(referralId => this.state.parcels[referralId].modeOfDelivery === "COLLECTION");
        var showDeliveryActions = selectedValues.every(referralId => this.state.parcels[referralId].modeOfDelivery === "DELIVERY"
            && this.state.parcels[referralId].scheduledDelivery
            && !isFuture(parse(this.state.parcels[referralId].scheduledDelivery, 'dd-MM-yyyy', new Date())));
        var showSendMessage = selectedValues.every(referralId => this.state.parcels[referralId].modeOfDelivery === "DELIVERY"
            && this.state.parcels[referralId].scheduledDelivery
            && isToday(parse(this.state.parcels[referralId].scheduledDelivery, 'dd-MM-yyyy', new Date())));
        return (<div>
            {showDeliveryActions
                ? (
                    <React.Fragment>
                        <Tooltip title="Delivery failed">
                            <IconButton aria-label="failed" size="large"
                                        onClick={() => this.performBatchAction("failed")}>
                                <ErrorOutlineRoundedIcon style={{color: whiteColor, fontSize: '35px'}}/>
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Delivery successful">
                            <IconButton aria-label="delivered" size="large"
                                        onClick={() => this.performBatchAction("delivered")}>
                                <CheckCircleOutlineRoundedIcon style={{color: whiteColor, fontSize: '35px'}}/>
                            </IconButton>
                        </Tooltip>
                        {showSendMessage && (<React.Fragment>
                            <Tooltip title="Send scheduled message">
                                <IconButton aria-label="sendScheduledMessage" size="large"
                                            onClick={() => this.setState({openSendScheduledTextDialog: true})}>
                                    <SendIcon style={{color: whiteColor, fontSize: '35px'}}/>
                                </IconButton>
                            </Tooltip>
                            <Dialog open={this.state.openSendScheduledTextDialog}>
                                <DialogTitle id="form-dialog-title">Send scheduled message</DialogTitle>
                                <DialogContent>
                                    <DialogContentText>
                                        Select the delivery window
                                    </DialogContentText>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
                                        <TimePicker ampm={false}
                                                    variant="inline"
                                                    label="Start of delivery window"
                                                    value={this.state.sendScheduledTextStartTime}
                                                    onChange={date => this.setState({sendScheduledTextStartTime: date})}/>
                                        <TimePicker ampm={false}
                                                    variant="inline"
                                                    label="End of delivery window"
                                                    value={this.state.sendScheduledTextEndTime}
                                                    onChange={date => this.setState({sendScheduledTextEndTime: date})}/>
                                    </MuiPickersUtilsProvider>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => this.setState({openSendScheduledTextDialog: false})}
                                            color="primary">
                                        Cancel
                                    </Button>
                                    <Button
                                        onClick={() => this.performBatchAction("sendScheduledText", this.state.sendScheduledTextStartTime, this.state.sendScheduledTextEndTime)}
                                        color="primary">
                                        Send
                                    </Button>
                                </DialogActions>
                            </Dialog>
                        </React.Fragment>)}
                    </React.Fragment>
                )
                : (showSchedule)
                    ? (
                        <React.Fragment>
                            <Tooltip title="Schedule delivery">
                                <IconButton aria-label="schedule" size="large"
                                            onClick={() => this.setState({openSchedule: true})}>
                                    <ScheduleIcon style={{color: whiteColor, fontSize: '35px'}}/>
                                </IconButton>
                            </Tooltip>
                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
                                <DatePicker open={this.state.openSchedule || false}
                                            onOpen={() => this.setState({openSchedule: true})}
                                            onClose={() => this.setState({openSchedule: false})}
                                            value={null}
                                            disablePast
                                            autoOk
                                            maxDate={this.endOfNextWeek()}
                                            onChange={date => this.performBatchAction("schedule", date)}
                                            TextFieldComponent={() => null}/>
                            </MuiPickersUtilsProvider>
                        </React.Fragment>
                    )
                    : (showCollected)
                        ? (
                            <React.Fragment>
                                <Tooltip title="Parcel collected">
                                    <IconButton aria-label="collected" size="large"
                                                onClick={() => this.setState({openCollected: true})}>
                                        <CheckCircleOutlineRoundedIcon style={{color: whiteColor, fontSize: '35px'}}/>
                                    </IconButton>
                                </Tooltip>
                                <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
                                    <DatePicker open={this.state.openCollected || false}
                                                onOpen={() => this.setState({openCollected: true})}
                                                onClose={() => this.setState({openCollected: false})}
                                                value={null}
                                                disableFuture
                                                autoOk
                                                onChange={date => this.performBatchAction("collected", date)}
                                                TextFieldComponent={() => null}/>
                                </MuiPickersUtilsProvider>
                            </React.Fragment>
                        )
                        : ""}
        </div>);
    }

    getReport() {
        ApiService.requestCsv(this.filteredPath('report'));
    }

    getDeliveriesReport() {
        ApiService.requestCsv(this.filteredPath('deliveriesreport'))
    }

    getCsv() {
        ApiService.requestCsv(this.filteredPath('csv'));
    }

    getSignpostingCsv() {
        ApiService.requestCsv(this.filteredPath('signposting'));
    }

    saveEmergencyParcel(date, numberOfParcels, collectionHub, comments) {
        return ApiService.request('/deliveries/emergency', 'PUT', {
          date: formatDate(date),
          numberOfParcels: numberOfParcels,
          collectionHub: collectionHub,
          comment: comments,
          referralType: 'FOOD_BANK'
        });
    }

    filteredPath(path) {
        console.log(path)
        return `/parcels/${path}?sort=${this.state.orderBy || 'status'},${this.state.order || 'asc'}`
            + `&modeOfDeliveryFilter=${this.state.modeOfDeliveryFilter}`
            + `&collectionHubFilter=${this.state.collectionHubFilter}`
            + `&parcelStatusFilter=${this.state.parcelStatusFilter}`
            + (this.state.dateFilter ? `&dateFilter=${format(this.state.dateFilter, 'dd-MM-yyyy')}` : '')
            + `${this.props.searchParam && `&searchString=${this.props.searchParam}` || ""}`
            + `${this.state.cashPilotStatusFilter && this.state.cashPilotStatusFilter !== "" && `&cashPilotStatusFilter=${this.state.cashPilotStatusFilter}` || ""}`
            + `${this.state.supportTypeFilter && this.state.supportTypeFilter !== "" && `&supportTypeFilter=${this.state.supportTypeFilter}` || ""}`;;
    }

    render() {
        const {classes} = this.props;
        const cashPilotAdministrator = this.props.user && (this.props.user.roles.includes('ROLE_ADMIN') || this.props.user.roles.includes('ROLE_CASH_PILOT_ADMINISTRATOR'))
        return (
            <GridContainer>
                <GridItem xs={12} sm={12} md={12}>
                    <Card>
                        <CardHeader color="primary">
                            <GridContainer alignItems='center'>
                                <GridItem md={2}>
                                    <h3 className={classes.cardTitleWhite}>Parcels</h3>
                                    {(this.props.searchParam && this.props.searchParam.length > 0) ? (
                                        <p className={classes.cardCategoryWhite}>Searching
                                            for: {this.props.searchParam}</p>) : ""}
                                    <p className={classes.cardCategoryWhite}>Showing {this.state.numberOfResults} parcels</p>
                                </GridItem>
                                <GridItem md={2}>
                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
                                        <DatePicker value={this.state.dateFilter}
                                                    clearable
                                                    autoOk
                                                    onChange={(date) => this.setDateFilter(date)}
                                                    classes={{root: classes.whiteColor, icon: classes.whiteColor}}
                                                    TextFieldComponent={(props) => (<Input onChange={props.onChange}
                                                                                           value={props.value}
                                                                                           onClick={props.onClick}
                                                                                           style={{color: whiteColor}}
                                                                                           endAdornment={<InputAdornment
                                                                                               position="end">
                                                                                               <IconButton>
                                                                                                   <DateRangeIcon
                                                                                                       style={{color: whiteColor}}/>
                                                                                               </IconButton>
                                                                                           </InputAdornment>}/>)}/>
                                    </MuiPickersUtilsProvider>
                                    <FormControl fullWidth>
                                        <InputLabel style={{color: whiteColor}} shrink>Delivery status</InputLabel>
                                        <Select value={this.state.parcelStatusFilter}
                                                onChange={this.setParcelStatusFilter}
                                                classes={{root: classes.whiteColor, icon: classes.whiteColor}}>
                                            <MenuItem value="all">All</MenuItem>
                                            <MenuItem value="pending">Pending</MenuItem>
                                            <MenuItem value="scheduled">Scheduled</MenuItem>
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem md={2}>
                                    <FormControl fullWidth>
                                        <InputLabel style={{color: whiteColor}} shrink>Collection hub</InputLabel>
                                        <Select value={this.state.collectionHubFilter}
                                                multiple
                                                onChange={this.setCollectionHubFilter}
                                                renderValue={(selected) => selected.map(v => v === 'all' && 'All' || v).join(', ')}
                                                classes={{root: classes.whiteColor, icon: classes.whiteColor}}>
                                            <MenuItem value="all">
                                                <Checkbox checked={this.state.collectionHubFilter.includes('all')} />
                                                <ListItemText primary={"All"} />
                                            </MenuItem>
                                            {this.props.collectionHubs
                                                       .filter(hub => hub.active)
                                                       .map(hub => (<MenuItem value={hub.name}>
                                                                        <Checkbox checked={this.state.collectionHubFilter.includes(hub.name)} />
                                                                        <ListItemText primary={hub.name} />
                                                                    </MenuItem>))}
                                        </Select>
                                    </FormControl>
                                    <FormControl fullWidth>
                                        <InputLabel style={{color: whiteColor}} shrink>Mode of delivery</InputLabel>
                                        <Select value={this.state.modeOfDeliveryFilter}
                                                onChange={this.setModeOfDeliveryFilter}
                                                classes={{root: classes.whiteColor, icon: classes.whiteColor}}>
                                            <MenuItem value="all">All</MenuItem>
                                            <MenuItem value="COLLECTION">Collection</MenuItem>
                                            <MenuItem value="DELIVERY">Delivery</MenuItem>
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem md={2}>
                                    {cashPilotAdministrator && (<FormControl fullWidth>
                                        <InputLabel style={{color: whiteColor}} shrink>Cash pilot status</InputLabel>
                                        <Select value={this.state.cashPilotStatusFilter}
                                                onChange={this.setCashPilotStatusFilter}
                                                disabled={this.state.dateFilter}
                                                classes={{root: classes.whiteColor, icon: classes.whiteColor}}>
                                            <MenuItem value="">All</MenuItem>
                                            <MenuItem value="INELIGIBLE">Ineligible</MenuItem>
                                            <MenuItem value="ELIGIBLE">Eligible</MenuItem>
                                            <MenuItem value="DECLINED">Declined</MenuItem>
                                            <MenuItem value="PARTICIPANT">Participant</MenuItem>
                                            <MenuItem value="DROPPED_OUT">Dropped out of cash pilot</MenuItem>
                                        </Select>
                                    </FormControl>)}
                                    {(cashPilotAdministrator || this.state.dateFilter) && (<FormControl fullWidth>
                                        <InputLabel style={{color: whiteColor}} shrink>Cash pilot support type</InputLabel>
                                        <Select value={this.state.supportTypeFilter}
                                                onChange={this.setSupportTypeFilter}
                                                disabled={!this.state.dateFilter && this.state.cashPilotStatusFilter !== 'TREATMENT'}
                                                classes={{root: classes.whiteColor, icon: classes.whiteColor}}>
                                            <MenuItem value="">All</MenuItem>
                                            <MenuItem value="FOOD_PARCEL">Food parcel</MenuItem>
                                            <MenuItem value="CASH_VOUCHER">Cash voucher</MenuItem>
                                        </Select>
                                    </FormControl>)}
                                </GridItem>
                                <GridItem md={4}>
                                    <GridContainer>
                                        <GridItem md={12}>
                                            <Tooltip title="Generate parcels report">
                                                <IconButton aria-label="parcels report" size="large"
                                                            onClick={() => this.getReport()}>
                                                    <ShoppingCartIcon style={{color: whiteColor, fontSize: '35px'}}/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Generate deliveries report">
                                                <IconButton aria-label="packing report" size="large"
                                                            onClick={() => this.getDeliveriesReport()}>
                                                    <LocalShippingRoundedIcon style={{color: whiteColor, fontSize: '35px'}}/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Download as CSV">
                                                <IconButton aria-label="csv" size="large" onClick={() => this.getCsv()}>
                                                    <CloudDownloadOutlinedIcon style={{color: whiteColor, fontSize: '35px'}}/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Download signposting CSV">
                                                <IconButton aria-label="csv" size="large"
                                                            onClick={() => this.getSignpostingCsv()}>
                                                    <DirectionsIcon style={{color: whiteColor, fontSize: '35px'}}/>
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Distribute emergency parcel">
                                                <IconButton aria-label="emergencyParcel" size="large"
                                                            onClick={() => this.setState({emergencyParcelDialogOpen: true})}>
                                                    <WarningIcon style={{color: whiteColor, fontSize: '35px'}}/>
                                                </IconButton>
                                            </Tooltip>
                                            {this.getActions()}
                                            <EmergencyParcelDialog open={this.state.emergencyParcelDialogOpen}
                                                                   handleClose={() => this.setState({emergencyParcelDialogOpen: false})}
                                                                   collectionHubs={this.props.collectionHubs}
                                                                   saveEmergencyParcel={this.saveEmergencyParcel}/>
                                        </GridItem>
                                        <GridItem md={12}>
                                            <Pagination count={this.state.numberOfPages} color="primary" showFirstButton
                                                        showLastButton page={this.state.page} onChange={this.changePage}/>
                                        </GridItem>
                                    </GridContainer>
                                </GridItem>
                            </GridContainer>
                        </CardHeader>
                        <CardBody>
                            <ParcelsTable
                                tableHeaderColor="primary"
                                tableHead={{
                                    "id": "ID",
                                    "fullName": "Name",
                                    "contactNumber": "Contact",
                                    "address": "Address",
                                    "postcode": "Postcode",
                                    "numberOfPeople": "People",
                                    "lastDeliveryDate": "Last delivery",
                                    "deliveriesReceived": "Parcels received",
                                    "parcelsLeft": "Parcels left"
                                }}
                                mappingFunctions={{
                                    "id": parcel => parcel.id,
                                    "fullName": parcel => parcel.guest.fullName,
                                    "contactNumber": parcel => parcel.guest.contactNumbers.toString(),
                                    "address": parcel => parcel.guest.address,
                                    "postcode": parcel => parcel.guest.postcode,
                                    "numberOfPeople": parcel => parcel.guest.numberOfPeople,
                                    "lastDeliveryDate": parcel => parcel.lastDeliveryDate,
                                    "deliveriesReceived": parcel => parcel.deliveriesReceived,
                                    "parcelsLeft": parcel => parcel.parcelsLeft
                                }}
                                parcels={this.state.parcels}
                                parcelsOrder={this.state.parcelsOrder}
                                performAction={this.performAction}
                                performReferralAction={this.performReferralAction}
                                archiveReferral={this.archiveReferral}
                                switchReferralTypeToReadyMeals={this.switchReferralTypeToReadyMeals}
                                performDeliveryAction={this.performDeliveryAction}
                                collectionHubs={this.props.collectionHubs}
                                orderBy={this.state.orderBy}
                                order={this.state.order}
                                setOrderBy={this.setOrderBy}
                                editReferral={this.editReferral}
                                fetchReferral={this.fetchReferral}
                                referrals={this.state.referrals}
                                selectedValues={this.state.selectedValues}
                                select={this.select}
                                deselect={this.deselect}
                                selectAll={this.selectAll}
                                deselectAll={this.deselectAll}
                                getOptions={this.props.getOptions}
                                user={this.props.user}
                                editFurtherAssessment={this.editFurtherAssessment}
                                createFurtherAssessment={this.createFurtherAssessment}
                            />
                        </CardBody>
                        <CardFooter>
                            <Pagination count={this.state.numberOfPages} color="primary" showFirstButton showLastButton
                                        page={this.state.page} onChange={this.changePage}/>
                        </CardFooter>
                    </Card>
                </GridItem>
            </GridContainer>
        );

    }


}

export default withStyles(styles)(Parcels);
