import AuthService from "services/AuthService.js";

const url = "https://sufra-nwl.org.uk/app"

const ApiService = {
    request: function(path, method, body, signals) {
      return fetch(`${url}${path}`, {
              ...signals,
              headers: Object.assign({}, { Authorization: 'Bearer ' + AuthService.getAccessToken() }, body && {'Content-Type': 'application/json'} || {}),
              method: method,
              body: body && JSON.stringify(body)
            })
        .then(res => {
          if (!res.ok) {
            if ([401, 403].indexOf(res.status) !== -1) {
                AuthService.logout();
                return Promise.reject();
            }
          }

          const contentType = res.headers.get("content-type");
          if (contentType && contentType.indexOf("application/json") !== -1) {
            return res.json();
          } else {
            return res.text();
          }
        })
    },

    requestCsv: function(path) {
      return fetch(`${url}${path}`, { headers: { Authorization: 'Bearer ' + AuthService.getAccessToken() } })
        .then(response => {
          if (!response.ok) {
            if ([401, 403].indexOf(response.status) !== -1) {
              AuthService.logout();
            }
            return Promise.reject();
          }
          return response;
        })
        .then(response => {
          response.blob().then(blob => {
            var url = window.URL.createObjectURL(blob);
            var a = document.createElement('a');
            a.href = url;
            a.download =  response.headers.get('Content-Disposition').split("filename=")[1];
            document.body.appendChild(a);
            a.click();
            a.remove();
          })
        })
    },

    getUrl: function() {
      return url
    }
};

export default ApiService;
