import React, {Component} from "react";
import PropTypes from "prop-types";
// @material-ui/core components
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import ErrorIcon from '@material-ui/icons/Error';
import {withStyles} from "@material-ui/core/styles";
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "@material-ui/core/Table";
import {isFuture, isToday, parse} from 'date-fns'
import {ArrowDropDown} from "@material-ui/icons";
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import {parseDate} from "../../util/DateUtils.js"
import SendIcon from '@material-ui/icons/Send';
import startOfWeek from "date-fns/startOfWeek";
import endOfWeek from "date-fns/endOfWeek";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FurtherAssessment from "components/Table/FurtherAssessment.js";
import FormControl from "@material-ui/core/FormControl";
import Typography from "@material-ui/core/Typography";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import InputLabel from "@material-ui/core/InputLabel";
import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {DatePicker, KeyboardDatePicker, MuiPickersUtilsProvider, TimePicker} from "@material-ui/pickers";
import TextField from "@material-ui/core/TextField";
import TableSortLabel from '@material-ui/core/TableSortLabel';
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import Checkbox from "@material-ui/core/Checkbox";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Button from "components/CustomButtons/Button.js";
import OptionWithOther from "components/OptionWithOther/OptionWithOther.js";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import Collapse from '@material-ui/core/Collapse';
import CheckCircleOutlineRoundedIcon from '@material-ui/icons/CheckCircleOutlineRounded';
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ErrorOutlineRoundedIcon from '@material-ui/icons/ErrorOutlineRounded';
import ScheduleIcon from '@material-ui/icons/Schedule';
import ReferralTimelineTable from "components/Table/ReferralTimelineTable.js";
import ArchiveRoundedIcon from '@material-ui/icons/ArchiveRounded';
import {dangerColor, warningColor, infoColor, successColor, grayColor} from "assets/jss/material-dashboard-react.js";
// core components
import styles from "assets/jss/material-dashboard-react/components/tableStyle.js";
import {enGB} from 'date-fns/locale'
import PermPhoneMsgIcon from "@material-ui/icons/PermPhoneMsg";

class ParcelsTable extends Component {
    constructor(props) {
        super(props);
        this.getActions = this.getActions.bind(this);
        this.getParcelStatusCell = this.getParcelStatusCell.bind(this);
        this.sendScheduledText = this.sendScheduledText.bind(this);
        this.setOpenSchedule = this.setOpenSchedule.bind(this);
        this.setOpenCollected = this.setOpenCollected.bind(this);
        this.changeGuestValue = this.changeGuestValue.bind(this);
        this.changeValue = this.changeValue.bind(this);
        this.confirmArchive = this.confirmArchive.bind(this);
        this.confirmSwitchToReadyMealReferral = this.confirmSwitchToReadyMealReferral.bind(this);
        this.viewFurtherAssessment = this.viewFurtherAssessment.bind(this);
        this.createFurtherAssessment = this.createFurtherAssessment.bind(this);
        this.getCashPilotStatusCell = this.getCashPilotStatusCell.bind(this);
        this.setEdit = this.setEdit.bind(this);
        this.state = {
            openSchedule: {},
            openCollected: {},
            open: {},
            edit: {},
            openSendScheduledTextDialog: false,
            sendScheduledTextStartTime: new Date(2020, 1, 1, 13, 0, 0, 0),
            sendScheduledTextEndTime: new Date(2020, 1, 1, 17, 0, 0, 0),
            sendScheduledTextParcelId: null,
            confirmArchiveReferral: null,
            furtherAssessmentDialogFurtherAssessmentId: null,
            furtherAssessmentDialogReferralId: null
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.parcelsOrder.some((id, idx) => this.props.parcelsOrder[idx] !== id)) {
            this.setState({open: {}, edit: {}})
        }
    }

    setOpen(key) {
        const edit = this.state.edit;
        const open = this.state.open;
        if (!open[key]) {
            this.props.fetchReferral(key).then(() => {
                open[key] = true;
                this.setState({open: open})
            });
        }
        else {
            delete edit[key];
            open[key] = false;
            this.setState({open: open, edit: edit});
        }
    }

    changeValue(id, field, value) {
        var edit = this.state.edit;
        edit[id][field] = value;
        this.setState({edit: edit});
    }

    changeGuestValue(id, field, value) {
        var edit = this.state.edit;
        edit[id].guest[field] = value;
        this.setState({edit: edit});
    }

    editReferral(key) {
        const edit = this.state.edit;
        this.props.editReferral(edit[key]);
        delete edit[key];
    }

    setEdit(key) {
        const edit = this.state.edit;
        const open = this.state.open;

        if (!open[key]) {
            this.props.fetchReferral(key).then(() => {
                open[key] = true;
                edit[key] = Object.assign({}, this.props.referrals[key]);
                edit[key].guest = Object.assign({}, this.props.referrals[key].guest)
                this.setState({edit: edit, open: open})
            });
        }
        else {
            if (!edit[key]) {
                edit[key] = Object.assign({}, this.props.referrals[key]);
                edit[key].guest = Object.assign({}, this.props.referrals[key].guest)
            }
            else {
                delete edit[key];
            }
            this.setState({edit: edit});
        }
    }

    setOpenSchedule(key, value) {
      var openSchedule = this.state.openSchedule;
      openSchedule[key] = value;
      this.setState({openSchedule: openSchedule});
    }

    setOpenCollected(key, value) {
      var openCollected = this.state.openCollected;
      openCollected[key] = value;
      this.setState({openCollected: openCollected});
    }

    endOfNextWeek() {
        var date = new Date();
        date.setDate(date.getDate() + 7);
        return endOfWeek(date, {weekStartsOn: 1});
    }

    sendScheduledText() {
        this.props.performAction(this.state.sendScheduledTextParcelId, "sendScheduledText", this.state.sendScheduledTextStartTime, this.state.sendScheduledTextEndTime)
            .then(response => {
              this.setState({ openSendScheduledTextDialog: false, sendScheduledTextParcelId: null})
            })
    }

    getCashPilotStatusCell(key, cashPilotAdministrator) {
        const { classes } = this.props;
        const parcel = this.props.parcels[key];
        if (cashPilotAdministrator) {
            return (
                <GridContainer>
                    <GridItem md={12}>
                        <FormControl fullWidth>
                            <InputLabel  shrink>Status</InputLabel>
                            <Select id={"select-cashPilotStatus-" + key}
                                    value={parcel['cashPilotStatusNew']}
                                    onChange={(event) => this.props.performReferralAction(key, 'setCashPilotStatus', event.target.value)}>
                                <MenuItem value={'NONE'}></MenuItem>
                                <MenuItem value={'ELIGIBLE'}>Eligible</MenuItem>
                                <MenuItem value={'INELIGIBLE'}>Ineligible</MenuItem>
                                <MenuItem value={'DECLINED'}>Declined</MenuItem>
                                <MenuItem value={'PARTICIPANT'}>Participant</MenuItem>
                                <MenuItem value={'DROPPED_OUT'}>Dropped out of cash pilot</MenuItem>
                            </Select>
                        </FormControl>
                    </GridItem>
                    {parcel['cashPilotStatusNew'] !== 'INELIGIBLE' && (<GridItem md={12}>
                        <FormControl fullWidth>
                            <InputLabel  shrink>Contacted by</InputLabel>
                            <Select id={"select-cashPilotContactedBy-" + key}
                                    value={parcel['cashPilotContactedBy'] || []}
                                    multiple
                                    fullWidth
                                    onChange={(event) => this.props.performReferralAction(key, 'setCashPilotContactedBy', event.target.value)}>
                                <MenuItem value={'TEXT'}>Text</MenuItem>
                                <MenuItem value={'PHONE'}>Phone</MenuItem>
                                <MenuItem value={'FACE_TO_FACE'}>Face-to-face</MenuItem>
                            </Select>
                        </FormControl>
                    </GridItem>)}
                    {parcel['cashPilotStatusNew'] === 'PARTICIPANT' && (<GridItem md={12}>
                        <FormControl fullWidth>
                            <InputLabel  shrink>Group</InputLabel>
                            <Select id={"select-cashPilotGroup-" + key}
                                    value={parcel['cashPilotGroup']}
                                    onChange={(event) => this.props.performReferralAction(key, 'setCashPilotGroup', event.target.value)}>
                                <MenuItem value={'CONTROL'}>Control</MenuItem>
                                <MenuItem value={'TREATMENT'}>Treatment</MenuItem>
                            </Select>
                        </FormControl>
                    </GridItem>)}
                    {parcel['cashPilotGroup'] === 'TREATMENT' && (<GridItem md={12}>
                        <FormControl fullWidth>
                            <InputLabel shrink>Support type</InputLabel>
                            <Select id={"select-supportType-" + key}
                                    value={parcel['supportType']}
                                    disabled={parcel['cashPilotStatusNew'] !== 'PARTICIPANT' || parcel['cashPilotGroup'] !== 'TREATMENT'}
                                    onChange={(event) => this.props.performReferralAction(key, 'setSupportType', event.target.value)}>
                                <MenuItem value={'FOOD_PARCEL'}>Food parcel</MenuItem>
                                <MenuItem value={'CASH_VOUCHER'}>Cash voucher</MenuItem>
                            </Select>
                        </FormControl>
                    </GridItem>)}
                </GridContainer>
            )
        }
        else {
            return (
                <GridContainer>
                    <GridItem md={12}>
                        <Typography className={classes.typography}>{parcel.currentSupportType}</Typography>
                    </GridItem>
                </GridContainer>
            )
        }
    }

    getParcelStatusCell(key) {
        const { classes } = this.props;
        const parcel = this.props.parcels[key];
        const text = parcel.textMessage;
        const textStatus = text && text.status;
        const today = parcel.scheduledDelivery && isToday(parse(parcel.scheduledDelivery, 'dd-MM-yyyy', new Date()));
        const messageIcon = (textStatus === "FAILED" && (<Tooltip title={"Message failed to send on " + text.date + ": " + text.error}>
                                                           <ErrorOutlineRoundedIcon style={{ color: dangerColor[2], fontSize: '20px' }}/>
                                                         </Tooltip>))
                         || (textStatus === "SUCCESSFUL" && (<Tooltip title={"Message delivered on " + text.date}>
                                                               <CheckCircleRoundedIcon style={{ color: successColor[2], fontSize: '20px' }}/>
                                                             </Tooltip>))
                         || (textStatus === "SENT" && (<Tooltip title={"Message sent on " + text.date}>
                                                         <CheckCircleOutlineRoundedIcon style={{ color: successColor[2], fontSize: '20px' }}/>
                                                       </Tooltip>))
                         || (!textStatus && today && (<Tooltip title="Send scheduled message">
                                                        <IconButton aria-label="sendScheduledMessage" onClick={() => this.setState({openSendScheduledTextDialog: true, sendScheduledTextParcelId: key})}>
                                                          <SendIcon style={{ color: successColor[2], fontSize: '20px' }}/>
                                                        </IconButton>
                                                      </Tooltip>))
        return (
          <React.Fragment>
            <GridContainer>
              <GridItem md={messageIcon ? 9 : 12}>
                <Typography className={classes.typography}>{parcel.parcelStatus}</Typography>
                {parcel.scheduledDelivery && !parcel.parcelStatus.includes(parcel.scheduledDelivery) ? (<Typography className={classes.typography}>Scheduled on {parcel.scheduledDelivery}</Typography>) : ""}
              </GridItem>
              {messageIcon && (<GridItem md={2}>
                                 {messageIcon}
                               </GridItem>)}
            </GridContainer>
          </React.Fragment>
        )
    }

    getActions(key) {
        const currentParcel = this.props.parcels[key];
        var delivery = currentParcel.modeOfDelivery == "DELIVERY";
        var scheduled = currentParcel.scheduledDelivery;
        var scheduledPast = scheduled && !isFuture(parse(scheduled, 'dd-MM-yyyy', new Date()));
        return (<div>
                    {(delivery && scheduled && scheduledPast)
                     ? (
                         <React.Fragment>
                         <Tooltip title="Delivery failed">
                           <IconButton aria-label="failed" size="medium" onClick={() => this.props.performAction(key, "failed")}>
                             <ErrorOutlineRoundedIcon style={{ color: dangerColor[2] }}/>
                           </IconButton>
                         </Tooltip>
                         <Tooltip title="Delivery successful">
                           <IconButton aria-label="delivered" size="medium" onClick={() => this.props.performAction(key, "delivered")}>
                             <CheckCircleOutlineRoundedIcon style={{ color: successColor[2] }}/>
                           </IconButton>
                         </Tooltip>
                         </React.Fragment>
                     )
                     : (delivery && !scheduled)
                     ? (
                         <React.Fragment>
                           <Tooltip title="Schedule delivery">
                             <IconButton aria-label="schedule" size="medium" onClick={() => this.setOpenSchedule(key, true)}>
                               <ScheduleIcon />
                             </IconButton>
                           </Tooltip>
                           <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
                             <DatePicker open={this.state.openSchedule[key] || false}
                                         onOpen={() => this.setOpenSchedule(key, true)}
                                         onClose={() => this.setOpenSchedule(key, false)}
                                         value={null}
                                         disablePast
                                         autoOk
                                         maxDate={this.endOfNextWeek()}
                                         minDate={startOfWeek(new Date(), {weekStartsOn: 1})}
                                         onChange={date => this.props.performAction(key, "schedule", date)}
                                         TextFieldComponent={() => null}/>
                           </MuiPickersUtilsProvider >
                         </React.Fragment>
                     )
                     : (!delivery)
                     ? (
                         <React.Fragment>
                           <Tooltip title="Parcel collected">
                             <IconButton aria-label="collected" size="medium" onClick={() => this.setOpenCollected(key, true)}>
                               <CheckCircleOutlineRoundedIcon style={{ color: successColor[2] }}/>
                             </IconButton>
                           </Tooltip>
                           <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
                             <DatePicker open={this.state.openCollected[key] || false}
                                         onOpen={() => this.setOpenCollected(key, true)}
                                         onClose={() => this.setOpenCollected(key, false)}
                                         value={null}
                                         disableFuture
                                         autoOk
                                         onChange={date => this.props.performAction(key, "collected", date)}
                                         TextFieldComponent={() => null}/>
                           </MuiPickersUtilsProvider >
                         </React.Fragment>
                     )
                     : ""}
                  <Tooltip title="Archive">
                    <IconButton aria-label="archive" size="medium"
                                onClick={() => this.setState({confirmArchiveReferral: { id: key, archiveReason: 'GUEST_REQUESTED', archiveComment: '' }})}>
                      <ArchiveRoundedIcon />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title="Change referral to Ready Meal referral">
                    <IconButton aria-label="switch referral" size="medium"
                                onClick={() => this.setState({confirmSwitchToReadyMealReferralId: key})}>
                      <SyncAltIcon />
                    </IconButton>
                  </Tooltip>
                </div>);
    }

    confirmArchive() {
        this.props.archiveReferral(this.state.confirmArchiveReferral.id, this.state.confirmArchiveReferral.archiveReason, this.state.confirmArchiveReferral.archiveComment, new Date())
            .then(() => this.setState({ confirmArchiveReferral: null }));
    }

    confirmSwitchToReadyMealReferral() {
        this.props.switchReferralTypeToReadyMeals(this.state.confirmSwitchToReadyMealReferralId)
            .then(() => this.setState({ confirmSwitchToReadyMealReferralId: null }));
    }

    viewFurtherAssessment(referralId, furtherAssessmentId) {
        this.setState({
            furtherAssessmentDialogFurtherAssessmentId: furtherAssessmentId,
            furtherAssessmentDialogReferralId: referralId
        })
    }

    createFurtherAssessment(referralId, furtherAssessment) {
        this.props.createFurtherAssessment(referralId, furtherAssessment)
            .then(id => {
                this.setState({
                    furtherAssessmentDialogFurtherAssessmentId: id,
                    furtherAssessmentDialogReferralId: referralId
                })
            })
    }

    changeSafeguardingStatus(parcelId, safeguardingFlag, safeguardingComment) {
        this.props.performReferralAction(parcelId, 'safeguarding', safeguardingFlag, safeguardingComment)
            .then(parcels => {
                this.setState({
                    safeguardingDialog: null,
                    safeguardingFlag: null,
                    safeguardingComment: null
                })
            })
    }

    render() {
        const { classes } = this.props;
        const hubsToInclude = [...new Set( Object.values(this.props.parcels).map(parcel => parcel.collectionHub)) ];
        const canEditFrequency = this.props.user?.roles?.includes('ROLE_ADVISOR') || this.props.user?.roles?.includes('ROLE_ADMIN')
        const cashPilotAdministrator = this.props.user && (this.props.user.roles.includes('ROLE_ADMIN') || this.props.user.roles.includes('ROLE_CASH_PILOT_ADMINISTRATOR'))
        return (
          <div className={classes.tableResponsive}>
              <FurtherAssessment
                  open={this.state.furtherAssessmentDialogReferralId}
                  readOnly={!(this.props.user && this.props.user.roles.includes('ROLE_ADMIN') || !this.state.furtherAssessmentDialogFurtherAssessmentId
                      || this.props.referrals[this.state.furtherAssessmentDialogReferralId].furtherAssessments.find((assessment) => assessment.id === this.state.furtherAssessmentDialogFurtherAssessmentId).parcelsLeft > 0)}
                  canEditFrequency={canEditFrequency}
                  onClose={() =>this.setState({
                      furtherAssessmentDialogFurtherAssessmentId: null,
                      furtherAssessmentDialogReferralId: null
                  })}
                  getOptions={this.props.getOptions}
                  id={this.state.furtherAssessmentDialogFurtherAssessmentId}
                  referral={this.props.referrals[this.state.furtherAssessmentDialogReferralId]}
                  assessment={this.state.furtherAssessmentDialogFurtherAssessmentId
                             && this.props.referrals[this.state.furtherAssessmentDialogReferralId].furtherAssessments.find((assessment) => assessment.id === this.state.furtherAssessmentDialogFurtherAssessmentId)}
                  editFurtherAssessment={this.props.editFurtherAssessment}
                  createFurtherAssessment={this.createFurtherAssessment}/>
            <Dialog open={this.state.openSendScheduledTextDialog}>
              <DialogTitle id="form-dialog-title">Send scheduled message</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Select the delivery window
                </DialogContentText>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
                    <TimePicker ampm={false}
                                variant="inline"
                                label="Start of delivery window"
                                value={this.state.sendScheduledTextStartTime}
                                onChange={date => this.setState({sendScheduledTextStartTime: date})}/>
                    <TimePicker ampm={false}
                                variant="inline"
                                label="End of delivery window"
                                value={this.state.sendScheduledTextEndTime}
                                onChange={date => this.setState({sendScheduledTextEndTime: date})}/>
                </MuiPickersUtilsProvider >
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.setState({openSendScheduledTextDialog: false, sendScheduledTextParcelId: null})} color="primary">
                  Cancel
                </Button>
                <Button onClick={this.sendScheduledText} color="primary">
                  Send
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={this.state.safeguardingDialog}>
              <DialogTitle id="form-dialog-title">{this.state.safeguardingFlag && 'Update' || 'Add'} safeguarding flag for {this.state.safeguardingDialog}</DialogTitle>
              <DialogContent>
                <TextField id="safeguardingComment"
                           label="Comment"
                           multiline
                           fullWidth
                           InputLabelProps={{shrink: true}}
                           value={this.state.safeguardingComment || ''}
                           onChange={ev => this.setState({ safeguardingComment: ev.target.value})}/>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => this.setState({safeguardingDialog: false, safeguardingComment: null, safeguardingFlag: null})} color="primary">
                  Cancel
                </Button>
                <Button onClick={() => this.changeSafeguardingStatus(this.state.safeguardingDialog, true, this.state.safeguardingComment)} color="primary">
                  {this.state.safeguardingFlag && 'Update' || 'Add'}
                </Button>
                {this.state.safeguardingFlag && (<Button onClick={() => this.changeSafeguardingStatus(this.state.safeguardingDialog, false, this.state.safeguardingComment)} color="primary">
                  Remove
                </Button>)}
              </DialogActions>
            </Dialog>
            <Dialog open={this.state.confirmArchiveReferral}
                    onClose={() => this.setState({confirmArchiveReferral: null})}>
              <DialogTitle id="confirm-archive-title">{"Archive referral?"}</DialogTitle>
              <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                      Are you sure you wish to archive referral {this.state.confirmArchiveReferral?.id} for {this.props.parcels[this.state.confirmArchiveReferral?.id]?.guest?.fullName}?
                  </DialogContentText>
                  <FormControl>
                      <InputLabel htmlFor="archiveReason">Archive reason</InputLabel>
                      <Select id="archiveReason"
                              name="archiveReason"
                              value={this.state.confirmArchiveReferral?.archiveReason || 'GUEST_REQUESTED'}
                              onChange={ev => this.setState({ confirmArchiveReferral: { ...this.state.confirmArchiveReferral, archiveReason: ev.target.value} })}>
                          <MenuItem value="DUPLICATE_RECEIVING_FOOD_PARCELS">Duplicate - receiving food parcels</MenuItem>
                          <MenuItem value="DUPLICATE_RECEIVING_READY_MEALS">Duplicate - receiving ready meals</MenuItem>
                          <MenuItem value="GUEST_REQUESTED">Guest has requested for parcels to stop</MenuItem>
                          <MenuItem value="MOVED_AWAY">Guest has moved away</MenuItem>
                          <MenuItem value="OTHER">Other</MenuItem>
                      </Select>
                  </FormControl><br/>
                  <TextField id="archiveComment"
                             label="Archive Comment"
                             multiline
                             InputLabelProps={{shrink: true}}
                             value={this.state.confirmArchiveReferral?.archiveComment || ''}
                             onChange={ev => this.setState({ confirmArchiveReferral: { ...this.state.confirmArchiveReferral, archiveComment: ev.target.value} })}/>
              </DialogContent>
              <DialogActions>
                  <Button onClick={() => this.setState({confirmArchiveReferral: null})} color="primary">
                      Cancel
                  </Button>
                  <Button onClick={this.confirmArchive} color="primary" autoFocus>
                      Archive
                  </Button>
              </DialogActions>
            </Dialog>
            <Dialog open={this.state.confirmSwitchToReadyMealReferralId} onClose={() => this.setState({confirmSwitchToReadyMealReferralId: null})}>
                <DialogTitle id="confirm-switch-referral-type-title">{"Switch to Ready Meal referral?"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you wish to change referral {this.state.confirmSwitchToReadyMealReferralId} for {this.props.parcels[this.state.confirmSwitchToReadyMealReferralId]?.guest?.fullName} to a Ready Meal referral?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => this.setState({confirmSwitchToReadyMealReferralId: null})} color="primary">
                        No
                    </Button>
                    <Button onClick={this.confirmSwitchToReadyMealReferral} color="primary" autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
            <Table className={classes.table}>
              {this.props.tableHead !== undefined ? (
                <TableHead className={classes[this.props.tableHeaderColor + "TableHeader"]}>
                  <TableRow className={classes.tableHeadRow}>
                    <TableCell>
                      <Checkbox id="select-all" name="select-all" checked={this.props.selectedValues.length === this.props.parcelsOrder.length} onChange={ev => ev.target.checked ? this.props.selectAll() : this.props.deselectAll()}/>
                    </TableCell>
                    {Object.keys(this.props.tableHead).map((prop, key) => {
                      return (<TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={key}>
                                <TableSortLabel active={this.props.orderBy === prop}
                                                direction={this.props.orderBy === prop ? this.props.order : 'asc'}
                                                onClick={ev => {this.props.setOrderBy(prop)}}>
                                  {this.props.tableHead[prop]}
                                </TableSortLabel>
                              </TableCell>)})}
                    <TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={'parcelStatus'}>
                      <TableSortLabel active={this.props.orderBy === 'parcelStatus'}
                                      direction={this.props.orderBy === 'parcelStatus' ? this.props.order : 'asc'}
                                      onClick={ev => {this.props.setOrderBy('parcelStatus')}}>
                        Parcel status
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={'collectionHub'}>
                      <TableSortLabel active={this.props.orderBy === 'collectionHub'}
                                      direction={this.props.orderBy === 'collectionHub' ? this.props.order : 'asc'}
                                      onClick={ev => {this.props.setOrderBy('collectionHub')}}>
                         Collection hub
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={'modeOfDelivery'}>
                      <TableSortLabel active={this.props.orderBy === 'modeOfDelivery'}
                                      direction={this.props.orderBy === 'modeOfDelivery' ? this.props.order : 'asc'}
                                      onClick={ev => {this.props.setOrderBy('modeOfDelivery')}}>
                         Mode of delivery
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={'parcelFrequency'}>
                      <TableSortLabel active={this.props.orderBy === 'parcelFrequency'}
                                      direction={this.props.orderBy === 'parcelFrequency' ? this.props.order : 'asc'}
                                      onClick={ev => {this.props.setOrderBy('parcelFrequency')}}>
                         Parcel frequency
                      </TableSortLabel>
                    </TableCell>
                    <TableCell className={classes.tableCell + " " + classes.tableHeadCell} key={'cashPilotStatus'}>
                      <TableSortLabel active={this.props.orderBy === 'cashPilotStatus'}
                                      direction={this.props.orderBy === 'cashPilotStatus' ? this.props.order : 'asc'}
                                      onClick={ev => {this.props.setOrderBy('cashPilotStatus')}}>
                         {cashPilotAdministrator ? "Cash pilot status" : "Support type"}
                      </TableSortLabel>
                    </TableCell>
                    <TableCell/>
                  </TableRow>
                </TableHead>
              ) : null}
              <TableBody>
                {this.props.parcelsOrder.map((parcelId, idx) => {
                  const referral = this.props.referrals[parcelId] || this.props.parcels[parcelId];
                  const editing = this.state.edit[parcelId]
                  const editedReferral = this.state.edit[parcelId] || referral
                  return referral && (
                    <React.Fragment>
                      <TableRow key={parcelId} className={classes.tableBodyRowSummary}>
                        <TableCell className={classes.tableCellSummary}>
                          <Tooltip title="View referral">
                            <IconButton aria-label="expand row" size="small" onClick={() => this.setOpen(parcelId)}>
                              {this.state.open[parcelId] ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                          </Tooltip>
                          <Checkbox id={"select-"+parcelId} name={"select-"+parcelId} checked={this.props.selectedValues.includes(parcelId)} onChange={ev => ev.target.checked ? this.props.select(parcelId) : this.props.deselect(parcelId)}/>
                        </TableCell>
                        
                        { Object.keys(this.props.tableHead).map((prop, key) => {
                          return (
                            <TableCell className={classes.tableCellSummary} key={key}>
                              {this.props.mappingFunctions[prop](this.props.parcels[parcelId])}
                                {prop === "contactNumber" && this.props.parcels[parcelId].permissionToContact
                                  && (<Tooltip title="The guest has consented to be contacted with information about relevant courses and opportunities.">
                                          <PermPhoneMsgIcon style={{ color: infoColor[0], fontSize: "1rem" }}/>
                                      </Tooltip>)}
                                {prop === "id"
                                  && (<IconButton aria-label="expand row" size="small" onClick={() => this.setState({safeguardingDialog: parcelId, safeguardingComment: this.props.parcels[parcelId].safeguardingComment, safeguardingFlag: this.props.parcels[parcelId].safeguarding})}>
                                              {this.props.parcels[parcelId].safeguarding
                                                && (<Tooltip title={`${this.props.parcels[parcelId].safeguardingComment} (${this.props.parcels[parcelId].safeguardingDate})`}>
                                                        <ErrorIcon style={{ color: warningColor[0], fontSize: "1rem" }}/>
                                                    </Tooltip>)
                                                || (<ErrorOutlineIcon style={{ color: grayColor[0], fontSize: "1rem" }}/>)}
                                      </IconButton>)}
                            </TableCell>
                          );
                        })}

                        <TableCell className={classes.tableCellSummary} key={"parcelStatus-"+parcelId}>
                          {this.getParcelStatusCell(parcelId)}
                        </TableCell>

                        <TableCell className={classes.tableCellSummary} key={"collectionhub-"+parcelId}>
                          <Select id={"select-collectionHub-"+parcelId}
                                  value={this.props.parcels[parcelId].collectionHub}
                                  onChange={(event) => this.props.performAction(parcelId, "changeHub", event.target.value)}
                                  fullWidth >
                            {this.props.collectionHubs.filter(hub => hub.active || hubsToInclude.includes(hub.name)).map(hub => (<MenuItem value={hub.name} disabled={!hub.active}>{hub.name}</MenuItem>))}
                          </Select>
                        </TableCell>

                        <TableCell className={classes.tableCellSummary} key={"modeOfDelivery-"+parcelId}>
                          <Select id={"select-modeOfDelivery-"+parcelId}
                                  value={this.props.parcels[parcelId].modeOfDelivery}
                                  onChange={(event) => this.props.performAction(parcelId, "switchDeliveryMode", event.target.value)}
                                  fullWidth >
                            <MenuItem value={"DELIVERY"}>Delivery</MenuItem>
                            <MenuItem value={"COLLECTION"}>Collection</MenuItem>
                          </Select>
                        </TableCell>
                        <TableCell className={classes.tableCellSummary} key={"parcelFrequency-"+parcelId}>
                          {(this.props.parcels[parcelId].furtherAssessmentPolicy === 'MONTHLY_PARCELS' && canEditFrequency && (
                              <Select id={"select-parcelFrequency-"+parcelId}
                                      value={this.props.parcels[parcelId].parcelFrequency}
                                      onChange={(event) => this.props.performAction(parcelId, "setParcelFrequency", event.target.value)}
                                      fullWidth >
                                <MenuItem value={"WEEKLY"}>Weekly</MenuItem>
                                <MenuItem value={"MONTHLY"}>Monthly</MenuItem>
                              </Select>
                          )) || (<Typography className={classes.typography}>{this.props.parcels[parcelId].parcelFrequency === 'WEEKLY' && 'Weekly' || 'Monthly'}</Typography>)}
                        </TableCell>
                        <TableCell className={classes.tableCellSummary} key={"cashPilotStatus-"+parcelId}>
                            {this.getCashPilotStatusCell(parcelId, cashPilotAdministrator)}
                        </TableCell>
                        <TableCell className={classes.tableCellSummary} key={"actions"}>
                          {this.getActions(parcelId)}
                          <Tooltip title="Edit referral">
                            <IconButton aria-label="expand row" size="small" onClick={() => this.setEdit(parcelId)}>
                              <EditOutlinedIcon />
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                      <TableRow key={parcelId + "Details"} className={classes.tableBodyRowDetail}>
                        <TableCell className={classes.tableDetailsCell}/>
                        <TableCell className={classes.tableDetailsCell} colSpan={Object.keys(this.props.tableHead).length + 4} key={"details"}>
                          <Collapse in={this.state.open[parcelId]} timeout="auto" unmountOnExit>
                            <form>
                              <GridContainer>
                                <GridItem xs={12} sm={12} md={3}>
                                  <OptionWithOther coreField="nappiesCore"
                                                   coreLabel="Nappies"
                                                   id={parcelId}
                                                   multiple
                                                   disabled={!editing}
                                                   object={editedReferral || {}}
                                                   changeValue={this.changeValue}
                                                   fullWidth
                                                   options={this.props.getOptions('NAPPIES').map(option => option.value)}
                                                   otherField="nappiesOther"/>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <OptionWithOther coreField="babyMilkCore"
                                                   coreLabel="Baby milk"
                                                   id={parcelId}
                                                   multiple
                                                   disabled={!editing}
                                                   object={editedReferral || {}}
                                                   changeValue={this.changeValue}
                                                   fullWidth
                                                   options={this.props.getOptions('BABY_MILK').map(option => option.value)}
                                                   otherField="babyMilkOther"/>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <OptionWithOther coreField="toiletriesCore"
                                                   coreLabel="Toiletries"
                                                   id={parcelId}
                                                   multiple
                                                   disabled={!editing}
                                                   object={editedReferral || {}}
                                                   changeValue={this.changeValue}
                                                   fullWidth
                                                   options={this.props.getOptions('TOILETRIES').map(option => option.value)}
                                                   otherField="toiletriesOther"/>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <OptionWithOther coreField="dietaryRequirementsCore"
                                                   coreLabel="Dietary requirements"
                                                   id={parcelId}
                                                   multiple
                                                   disabled={!editing}
                                                   object={editedReferral && editedReferral.guest || {}}
                                                   changeValue={this.changeGuestValue}
                                                   fullWidth
                                                   options={this.props.getOptions('DIETARY_REQUIREMENTS').map(option => option.value)}
                                                   otherField="dietaryRequirementsOther"/>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <TextField id="accessibilityIssues"
                                             label="Accessibility issues"
                                             disabled={!editing}
                                             multiline
                                             fullWidth
                                             value={editedReferral && editedReferral.guest && editedReferral.guest.accessibilityIssues || ''}
                                             onChange={ev => {
                                                this.changeGuestValue(parcelId, "accessibilityIssues", ev.target.value)
                                             }}/>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <OptionWithOther coreField="languagesSpokenCore"
                                                   coreLabel="Languages spoken"
                                                   id={parcelId}
                                                   multiple
                                                   disabled={!editing}
                                                   object={editedReferral && editedReferral.guest || {}}
                                                   changeValue={this.changeGuestValue}
                                                   fullWidth
                                                   options={this.props.getOptions('LANGUAGES_SPOKEN').map(option => option.value)}
                                                   otherField="languagesSpokenOther"/>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <TextField id="furtherInfo"
                                             label="Further info"
                                             disabled={!editing}
                                             multiline
                                             fullWidth
                                             value={editedReferral.furtherInfo || ''}
                                             onChange={ev => this.changeValue(parcelId, "furtherInfo", ev.target.value)}/>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <FormControlLabel
                                    control={<Checkbox id="cookingFacilities" name="cookingFacilities" disabled={!editing} checked={editedReferral.cookingFacilities || false} onChange={ (ev) => this.changeValue(parcelId, "cookingFacilities", ev.target.checked) } />}
                                    label="Cooking facilities"
                                  />
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <TextField id="reasonForDelivery"
                                             label="Reason for delivery"
                                             disabled={!editing}
                                             multiline
                                             fullWidth
                                             value={editedReferral && editedReferral.reasonForDelivery || ''}
                                             onChange={ev => this.changeValue(parcelId, "reasonForDelivery", ev.target.value)}/>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <FormControl fullWidth>
                                    <InputLabel htmlFor="frequencyOfDelivery">Frequency of delivery</InputLabel>
                                    <Select id="frequencyOfDelivery" name="frequencyOfDelivery" disabled={!editing} value={editedReferral.frequencyOfDelivery || ""} onChange={ev => this.changeValue(parcelId, "frequencyOfDelivery", ev.target.value)}>
                                      <MenuItem value="WEEKLY">Weekly</MenuItem>
                                      <MenuItem value="FORTNIGHTLY">Fortnightly</MenuItem>
                                    </Select>
                                  </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <TextField id="numberOfDeliveries"
                                             label="Number of deliveries"
                                             disabled={!editing}
                                             value={editedReferral.numberOfDeliveries}
                                             onChange={ev => this.changeValue(parcelId, "numberOfDeliveries", ev.target.value)}
                                             type="number"/>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}/>

                                {cashPilotAdministrator && (<React.Fragment>
                                <GridItem xs={12} sm={12} md={3}>
                                  <FormControl fullWidth>
                                    <InputLabel htmlFor="cashPilotStatusNew">Cash pilot status</InputLabel>
                                    <Select id="cashPilotStatusNew"
                                            name="cashPilotStatusNew"
                                            disabled={!editing}
                                            value={editedReferral.cashPilotStatusNew || ""}
                                            onChange={ev => {
                                                var currentCashPilotStatus = editedReferral.cashPilotStatusNew
                                                var newCashPilotStatus = ev.target.value
                                                this.changeValue(parcelId, "cashPilotStatusNew", newCashPilotStatus)
                                                if (newCashPilotStatus === 'PARTICIPANT' && newCashPilotStatus !== currentCashPilotStatus) {
                                                    this.changeValue(parcelId, 'dateParticipationConfirmed', new Date())
                                                }
                                                if (newCashPilotStatus === 'DROPPED_OUT' && newCashPilotStatus !== currentCashPilotStatus) {
                                                    this.changeValue(parcelId, 'dateDroppedOutOfCashPilot', new Date())
                                                }
                                            }}>
                                        <MenuItem value={'NONE'}></MenuItem>
                                        <MenuItem value={'ELIGIBLE'}>Eligible</MenuItem>
                                        <MenuItem value={'INELIGIBLE'}>Ineligible</MenuItem>
                                        <MenuItem value={'DECLINED'}>Declined</MenuItem>
                                        <MenuItem value={'PARTICIPANT'}>Participant</MenuItem>
                                        <MenuItem value={'DROPPED_OUT'}>Dropped out of cash pilot</MenuItem>
                                    </Select>
                                  </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                    <FormControl fullWidth>
                                        <InputLabel  shrink>Contacted by</InputLabel>
                                        <Select id={"select-cashPilotContactedBy-" + parcelId}
                                                value={editedReferral.cashPilotContactedBy || []}
                                                disabled={!editing}
                                                multiple
                                                fullWidth
                                                onChange={(event) => this.changeValue(parcelId, "cashPilotContactedBy", event.target.value)}>
                                            <MenuItem value={'TEXT'}>Text</MenuItem>
                                            <MenuItem value={'PHONE'}>Phone</MenuItem>
                                            <MenuItem value={'FACE_TO_FACE'}>Face-to-face</MenuItem>
                                        </Select>
                                    </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                   <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
                                       <KeyboardDatePicker value={editedReferral.dateParticipationConfirmed && parseDate(editedReferral.dateParticipationConfirmed) || null}
                                                           autoOk
                                                           label="Date participation confirmed"
                                                           variant="inline"
                                                           onChange={(date) => this.changeValue(parcelId, "dateParticipationConfirmed", date)}
                                                           disabled={!editing}
                                                           format="dd-MM-yyyy"/>
                                   </MuiPickersUtilsProvider >
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                   <MuiPickersUtilsProvider utils={DateFnsUtils} locale={enGB}>
                                       <KeyboardDatePicker value={editedReferral.dateDroppedOutOfCashPilot && parseDate(editedReferral.dateDroppedOutOfCashPilot) || null}
                                                           autoOk
                                                           label="Date dropped out of cash pilot"
                                                           variant="inline"
                                                           onChange={(date) => this.changeValue(parcelId, "dateDroppedOutOfCashPilot", date)}
                                                           disabled={!editing}
                                                           format="dd-MM-yyyy"/>
                                   </MuiPickersUtilsProvider >
                                </GridItem>

                                <GridItem xs={12} sm={12} md={3}>
                                  <FormControl fullWidth>
                                    <InputLabel htmlFor="cashPilotGroup">Cash pilot group</InputLabel>
                                    <Select id="cashPilotGroup"
                                            name="cashPilotGroup"
                                            disabled={!editing || editedReferral.cashPilotStatusNew !== 'PARTICIPANT'}
                                            value={editedReferral.cashPilotGroup || ""}
                                            onChange={ev => {
                                                this.changeValue(parcelId, "cashPilotGroup", ev.target.value)
                                            }}>
                                          <MenuItem value="CONTROL">Control group</MenuItem>
                                          <MenuItem value="TREATMENT">Treatment group</MenuItem>
                                    </Select>
                                  </FormControl>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={3}>
                                  <FormControl fullWidth>
                                    <InputLabel htmlFor="supportType">Support type</InputLabel>
                                    <Select id="supportType"
                                            name="supportType"
                                            disabled={!editing || editedReferral.cashPilotGroup !== 'TREATMENT'}
                                            value={editedReferral.supportType || ""}
                                            onChange={ev => {
                                                this.changeValue(parcelId, "supportType", ev.target.value)
                                            }}>
                                          <MenuItem value="FOOD_PARCEL">Food parcel</MenuItem>
                                          <MenuItem value="CASH_VOUCHER">Cash voucher</MenuItem>
                                    </Select>
                                  </FormControl>
                                </GridItem></React.Fragment>)}

                                <GridItem xs={12} sm={12} md={12}>
                                    <Button color="primary" disabled={!editing} onClick={e => this.editReferral(parcelId)}>Update referral</Button>
                                </GridItem>
                                <GridItem xs={12} sm={12} md={12}>
                                  <GridContainer>
                                    <GridItem md={6}>
                                      <ReferralTimelineTable
                                        tableHeaderColor="primary"
                                        tableHead={{"ID": timelineEvent => timelineEvent.id,
                                                    "Date": timelineEvent => timelineEvent.date}}
                                        timeline={referral && referral.timeline || []}
                                        performDeliveryAction={this.props.performDeliveryAction}
                                        viewFurtherAssessment={this.viewFurtherAssessment}
                                        id={referral.id}
                                      />
                                    </GridItem>
                                  </GridContainer>
                                </GridItem>
                              </GridContainer>
                            </form>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
              </TableBody>
            </Table>
          </div>
        );
    }
}

export default withStyles(styles)(ParcelsTable);

ParcelsTable.defaultProps = {
  tableHeaderColor: "gray"
};

ParcelsTable.propTypes = {
  tableHeaderColor: PropTypes.oneOf([
    "warning",
    "primary",
    "danger",
    "success",
    "info",
    "rose",
    "gray"
  ]),
  tableHead: PropTypes.object
};
